






import Vue from "vue";

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
    },
    to: {
      type: String,
    },
  },
  name: "AuthLink",
  data: () => {
    return {};
  },
});
